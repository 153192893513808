import React, { Component } from 'react';
import { Link } from "gatsby"

import banner from '../scss/banner.module.scss'

class CookieBanner extends Component {
  // constructor to set state and bind "this"
  constructor(props) {
      super(props);
      this.state = {showBanner: true};
      this.handleClick = this.handleClick.bind(this);
    }

  // function to handle the click
    handleClick() {
    this.setState(prevState => ({
      showBanner: !prevState.showBanner
    }));
  }

  render() {
    // the modal you will toggle on and off
    const modal = (
      <div className={banner.cookieWrapper}>
        <p>This website uses session and persistent cookies to understand our audience and improve site experience.  To learn more, read our <Link to="/privacy#cookies" id="cookie_banner_privacy" title="Privacy Policy">Privacy Policy.</Link> <button id="cookie_banner_close" className="banner confirm" onClick={this.handleClick}>Close</button></p>
      </div>
    );

    // the return() to put your default HTML into the DOM
    return (
      <div className={banner.cookieBanner}>
        {this.state.showBanner ? modal : ''}
      </div>
    );
  }
}

export default CookieBanner;

