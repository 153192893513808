import React,{useRef, useEffect} from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';

import 'react-h5-audio-player/src/styles.scss';

import audio from '../scss/player.module.scss'

function playerButton () {
  window.dataLayer.push({'event': 'Button play audio'});
}

function Player() {
  const player = useRef();

  useEffect(() => {
    const audioPlayer = document.querySelector('.rhap-wrapper')
    setTimeout(() => {
      audioPlayer.setAttribute("style","opacity:1");
    }, 1000);
  }, []);
  // const audiofunctionPlay = () => {
  //   player.current.audio.current.play();
  // };
  const audiofunctionPause = () => {
    player.current.audio.current.pause();
  };

  return (
    <>
    {/* <ul>
    <li><button onClick={audiofunctionPlay} >play</button></li>
    <li><button onClick={audiofunctionPause}>paws</button></li>
    </ul> */}
    <div id="audioplayer" className={`rhap-wrapper ${audio.container}`}>
      <div className={audio.wrapper}>
        <AudioPlayer
        layout="horizontal-reverse"
        // header="Listen to something interesting"
        customAdditionalControls={[]}
        customVolumeControls={[]}
        customControlsSection={
          [
            <p>Listen to the Voices of Sonic Diversity or <button id="launchvideo" onClick={audiofunctionPause} data-modal-open="audiomontage">launch the video</button></p>,
            RHAP_UI.MAIN_CONTROLS,
          ]
        }
        customProgressBarSection={
          [
            RHAP_UI.PROGRESS_BAR,
            RHAP_UI.CURRENT_LEFT_TIME,
          ]
        }
        showDownloadProgress={false}
        showJumpControls={false}
        src="/media/SFSD_audio.mp3"
        ref={player}
        // onPlay={e => console.log("onPlay")}
        onPlay={playerButton}
        // other props here
        />
      </div>
    </div>
    </>
  )
}
export default Player