import React from 'react';
import CookieBanner from "../components/cookie"
import Player from "../components/player"

import sticky from '../scss/lowersticky.module.scss'

function LowerSticky() {

  return (
    <div id="lowerSticky" className={sticky.container}>
      <Player />
      <CookieBanner />
    </div>
  )
}
export default LowerSticky